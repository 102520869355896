import handleRequest from "@/http";
const state = {
  activeCompany: null,
};

const getters = {
  getSelectedCompany: (state) => state.activeCompany,
};

const mutations = {
  setCompany(state, company) {
    state.activeCompany = company;
  },
};

const actions = {
  // Dashboard
  setActiveCompany({ commit }, payload) {
    commit("setCompany", payload);
  },
  async searchDashboard({ commit, state }, payload) {
    const queryString = [
      `limit=${payload?.limit || ""}`,
      `page=${payload?.page || ""}`,
      `user=${payload?.customer || ""}`,
    ].join("&");
    try {
      const { data } = await handleRequest.post(`/search?${queryString}`, {
        model: payload?.model,
        criteria: payload?.criteria,
      });
      return data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  },
  async getDashboardStats({ commit, state }, payload) {
    const endpoint = payload.customer
      ? `/transactions/dashboard?user=${payload.customer}`
      : "/transactions/dashboard";
    try {
      const { data } = await handleRequest.get(endpoint);
      return data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  },
  async getAllSubscriptions({ commit, state }, payload) {
    try {
      let requestUrl = "/subscriptions";
      const queryString = [
        `limit=${payload?.limit || ""}`,
        `page=${payload?.page || ""}`,
      ];
      if (payload?.customer) {
        queryString.push(`user=${payload?.customer}`);
      }
      const { data } = await handleRequest.get(
        `${requestUrl}?${queryString.join("&")}`
      );
      return data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  },
  async getAllTransactions({ commit, state }, payload) {
    try {
      let requestUrl = "/transactions";
      const queryString = [
        `limit=${payload?.limit || ""}`,
        `page=${payload?.page || ""}`,
      ];
      if (payload?.customer) {
        queryString.push(`user=${payload?.customer}`);
      }
      const { data } = await handleRequest.get(
        `${requestUrl}?${queryString.join("&")}`
      );
      return data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  },
  async getAllOrders({ commit, state }, payload) {
    try {
      const queryString = [
        `limit=${payload?.limit || ""}`,
        `page=${payload?.page || ""}`,
      ];
      if (payload?.customer) {
        queryString.push(`customer=${payload?.customer}`);
      }
      const { data } = await handleRequest.get(
        `/orders?${queryString.join("&")}`
      );
      return data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  },
  // Get Companies
  async getAllCompanies({ commit, state }, payload) {
    try {
      const queryString = [
        `limit=${payload?.limit || ""}`,
        `page=${payload?.page || ""}`,
      ];
      if (payload?.customer) {
        queryString.push(`user=${payload?.customer}`);
      }
      const { data } = await handleRequest.get(
        `/admin/users?${queryString.join("&")}`
      );
      return data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  },
  // KYC
  async getUserKyc({ commit, state }, payload) {
    try {
      const queryString = [
        `limit=${payload?.limit || ""}`,
        `page=${payload?.page || ""}`,
      ];
      if (payload?.customer) {
        queryString.push(`user=${payload?.customer}`);
      }
      const { data } = await handleRequest.get(`/kyc?${queryString.join("&")}`);
      return data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  },
  async verifyKyc({ commit, state }, payload) {
    try {
      await handleRequest.put(`/kyc/${payload.documentId}/verify`);
    } catch (error) {
      console.log(error);
      throw error;
    }
  },
  async rejectKyc({ commit, state }, payload) {
    try {
      await handleRequest.put(`/kyc/${payload.documentId}/reject`, payload);
    } catch (error) {
      console.log(error);
      throw error;
    }
  },
  // Create new company
  async createNewCompany({ commit, state }, payload) {
    try {
      await handleRequest.post(`/admin/users`, payload);
    } catch (error) {
      console.log(error);
      throw error;
    }
  },
  async updateCompany({ commit, state }, payload) {
    try {
      await handleRequest.put(`/admin/users/${payload.user}`, payload);
    } catch (error) {
      console.log(error);
      throw error;
    }
  },
  // Get company users
  async getCompanyAccounts({ commit, state }, payload) {
    try {
      const queryString = [
        `limit=${payload?.limit || ""}`,
        `page=${payload?.page || ""}`,
        `user=${payload?.customer || ""}`,
      ];
      const { data } = await handleRequest.get(
        `/admin/users?${queryString.join("&")}`
      );
      return data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  },
  // Get All customers
  async getAllCustomers({ commit, state }, payload) {
    try {
      const queryString = [
        `limit=${payload?.limit || ""}`,
        `page=${payload?.page || ""}`,
      ];
      const { data } = await handleRequest.get(
        `/customers?${queryString.join("&")}`
      );
      return data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  },
  async getCompanyApiLogs({ commit, state }, payload) {
    try {
      const queryString = [
        `limit=${payload?.limit || ""}`,
        `page=${payload?.page || ""}`,
        `user=${payload?.customer || ""}`,
      ];
      const { data } = await handleRequest.get(
        `/account/api/logs?${queryString.join("&")}`
      );
      return data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  },
  async getSingleApiLog({ commit, state }, payload) {
    try {
      const { data } = await handleRequest.get(
        `/account/api/logs/${payload.logId}?user=${payload?.customer}`
      );
      return data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  },
  // Get Webhooks
  async getAllWebhooks({ commit, state }, payload) {
    const queryString = [
      `limit=${payload?.limit || ""}`,
      `page=${payload?.page || ""}`,
      `user=${payload?.customer || ""}`,
    ];
    try {
      const { data } = await handleRequest.get(
        `/account/api/webhook?${queryString.join("&")}`
      );
      return data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  },
  async setWebhookUrl({ commit, state }, payload) {
    try {
      await handleRequest.post(
        `/account/api/webhook?user=${payload.customer}`,
        payload
      );
    } catch (error) {
      console.log(error);
      throw error;
    }
  },
  async verifyWebHook({ commit, state }, payload) {
    try {
      await handleRequest.put(
        `/account/api/webhook/verify?user=${payload.customer}`,
        payload
      );
    } catch (error) {
      console.log(error);
      throw error;
    }
  },
  async getCompanyWebhookLogs({ commit, state }, payload) {
    try {
      const queryString = [
        `limit=${payload?.limit || ""}`,
        `page=${payload?.page || ""}`,
        `user=${payload?.customer || ""}`,
      ];
      const { data } = await handleRequest.get(
        `/account/api/webhook-log?${queryString.join("&")}`
      );
      return data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  },
  async getSingleWebhookLog({ commit, state }, payload) {
    try {
      const { data } = await handleRequest.get(
        `/account/api/webhook-log/${payload.logId}?user=${
          payload?.customer || ""
        }`
      );
      return data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  },
  // Request account
  async requestAccounts({ commit, state }, payload) {
    try {
      const queryString = [
        `limit=${payload?.limit || ""}`,
        `page=${payload?.page || ""}`,
        `user=${payload?.customer || ""}`,
      ];
      const { data } = await handleRequest.get(
        `/account?${queryString.join("&")}`
      );
      return data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  },
  async requestAccountAddresses({ commit, state }, payload) {
    try {
      const queryString = [
        `limit=${payload?.limit || ""}`,
        `page=${payload?.page || ""}`,
        `user=${payload?.customer || ""}`,
      ];
      const { data } = await handleRequest.get(
        `/account/address/${payload?.accountId}?${queryString.join("&")}`
      );
      return data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  },
  async fundAccount({ commit, state }, payload) {
    try {
      const queryString = [
        `limit=${payload?.limit || ""}`,
        `page=${payload?.page || ""}`,
        `user=${payload?.customer || ""}`,
      ];
      const { data } = await handleRequest.get(
        `/account/${payload?.accountId}/fund?${queryString.join("&")}`
      );
      return data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  },
  async getAllApiKeys({ commit, state }, payload) {
    const queryString = [`user=${payload?.customer || ""}`];
    try {
      const { data } = await handleRequest.post(
        `/account/api/key?${queryString.join("&")}`
      );
      return data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  },
  async generateNewApiKey({ commit, state }, payload) {
    const queryString = [`user=${payload?.customer || ""}`];
    try {
      const { data } = await handleRequest.put(
        `/account/api/key/renew/${payload.keyId}?${queryString.join("&")}`
      );
      return data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  },
  async revokeApiKey({ commit, state }, payload) {
    const queryString = [`user=${payload?.customer || ""}`];
    try {
      const { data } = await handleRequest.put(
        `/account/api/key/revoke/${payload.keyId}?${queryString.join("&")}`
      );
      return data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
