export const ComanyRoutes = {
  path: "/dashboard/company",
  meta: {
    requiresAuth: true,
  },
  component: () => import("@/layouts/_companies.vue"),
  children: [
    {
      path: "/dashboard/company",
      name: "CompanyOverview",
      component: () =>
        import("@/views/dashboard/companies/CompanyAccounts.vue"),
    },
    {
      path: "/dashboard/company/transactions",
      name: "CompanyTransactions",
      component: () =>
        import("@/views/dashboard/companies/CompanyTransactions.vue"),
    },
    {
      path: "/dashboard/company/webhook",
      name: "CompanyWebhook",
      component: () => import("@/views/dashboard/companies/CompanyWebhook.vue"),
    },
    {
      path: "/dashboard/company/subscriptions",
      name: "Subscriptions",
      component: () => import("@/views/dashboard/companies/CompanySubscriptions.vue"),
    },
    {
      path: "/dashboard/company/api",
      name: "CompanyApi",
      component: () => import("@/views/dashboard/companies/CompanyApi.vue"),
    },
    {
      path: "/dashboard/company/keys",
      name: "CompanyKeys",
      component: () => import("@/views/dashboard/companies/CompanyKeys.vue"),
    },
    {
      path: "/dashboard/company_account/customer/:id",
      name: "CompanyUser",
      component: () => import("@/views/dashboard/companies/CompanyUser.vue"),
    },
  ],
};
