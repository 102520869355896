export const DashboardRoutes = {
  path: "/overview",
  redirect: "/dashboard",
  meta: {
    requiresAuth: true,
  },
  component: () => import("@/layouts/_dashboard.vue"),
  children: [
    {
      path: "/dashboard",
      name: "Overview",
      component: () => import("@/views/dashboard/Overview.vue"),
    },
    {
      path: "/dashboard/companies",
      name: "Companies",
      component: () => import("@/views/dashboard/companies/AllCompanies.vue"),
    },
    {
      path: "/dashboard/transactions",
      name: "Transactions",
      component: () => import("@/views/dashboard/transactions/Index.vue"),
    },
    {
      path: "/dashboard/kyc",
      name: "Kyc",
      component: () => import("@/views/dashboard/kyc/Index.vue"),
    },
    {
      path: "/dashboard/settings",
      name: "Settings",
      component: () => import("@/views/dashboard/settings/Index.vue"),
    },
  ],
};
