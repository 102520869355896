<template>
  <a
    href="javascript:void(0)"
    class="d-inline-flex align-items-center nx-link my-4 text__body"
    @click="handleBackButton"
  >
    <i class="bi bi-arrow-left-square me-2"></i>
    Go Back
  </a>
</template>

<script>
export default {
  name: "Goback",
  methods: {
    handleBackButton() {
      this.$router.go(-1);
    },
  },
};
</script>

<style></style>
