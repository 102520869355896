import handleRequest from "@/http";
import router from "@/router";
const state = {
  user: null,
  accessToken: null,
  refreshToken: null,
  isAuthenticated: false,
};

const getters = {
  isAuthenticated: (state) => state.isAuthenticated,
  isAdmin: (state) => state.isAdmin,
  getUser: (state) => state.user,
};

const mutations = {
  setUser(state, user) {
    state.user = user;
  },
  setIsAuthenticated(state, isAuthenticated) {
    state.isAuthenticated = isAuthenticated;
  },
  setAccessToken(state, accessToken) {
    state.accessToken = accessToken;
  },
  setIsAdmin(state, isAdmin) {
    state.isAdmin = isAdmin;
  },
  setRefToken(state, refreshToken) {
    state.refreshToken = refreshToken;
  },
};

const actions = {
  async handleUserLogin({ commit, dispatch, state }, credentials) {
    try {
      const response = await handleRequest.post("/auth/login", credentials);
      const data = response.data;
      await commit("setAccessToken", data.authToken);
      await commit("setIsAdmin", data.isAdmin);
      await dispatch("getLoggedInUser");
      await commit("setIsAuthenticated", true);
      return data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
  async getLoggedInUser({ commit, state }, credentials) {
    try {
      const response = await handleRequest.get("/auth/loggedIn");
      // console.log(response?.data);
      await commit("setUser", response?.data);
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
  async handleUserLogout({ commit }) {
    try {
      router.push({
        name: "home",
      });
      setTimeout(async () => {
        commit("setAccessToken", null);
        commit("setRefToken", null);
        commit("setIsAuthenticated", false);
        commit("setUser", null);
      }, 5000);
      localStorage.setItem("sessionTime", 0);
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
  async activateAccount({ commit }, payload) {
    try {
      await handleRequest.put(`/admin/account/approve/${payload.user}`);
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
  async shutdownAccount({ commit }, payload) {
    try {
      await handleRequest.put(`/admin/account/disapprove/${payload.user}`);
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
  async makeAdmin({ commit }, payload) {
    try {
      await handleRequest.put(`/admin/add/${payload.user}`);
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
  async removeAdmin({ commit }, payload) {
    try {
      await handleRequest.put(`/admin/remove/${payload.user}`);
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
