<template>
  <router-view v-slot="{ Component }">
    <transition name="fade" mode="out-in">
      <component :is="Component" />
    </transition>
  </router-view>
</template>
<script>
import { useHead } from "unhead";
export default {
  setup() {
    useHead({
      title: "Admin Area",
    });
  },
};
</script>
